import type {
  DefaultError,
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
  UseQueryResult,
} from '@tanstack/react-query'

import type { ZOutputSchema } from '@apsys/sdk-console'

/**
 * Similar to the Data Statuses, but it's a subset tailored to our
 * usage of the react query API.
 *
 * @category Status
 */
export enum QueryDataStatus {
  /**
   * Idle means that there is no data and that it is available to load.
   * Idle transitions to 'Loading' only.
   */
  Idle,

  /**
   * Loading indicates that the DataStatus is populating its data for the first time.
   * Loading transitions to 'Ready' or 'Failed'.
   */
  Loading,

  /**
   * Ready indicates the DataStatus is populated with non-null, defined data.
   * Ready transitions to 'Stale' or 'Updating'.
   */
  Ready,

  /**
   * Updating indicates the DataStatus is populated with non-null, defined data which
   * may be in the process of being submitted to the backend, or may be in the process
   * of being refreshed.
   */
  Updating,

  /**
   * The application has encountered an error which can be handled in a way that is
   * meaningful to the user. This information will be carried in the 'messages' property.
   */
  Failed,
}

export type BaseQueryStatus<TData = unknown, TError = unknown> = {
  data?: TData
  error: TError | null
  status: QueryDataStatus
}

export type BaseInfiniteQueryStatus<TData = unknown, TError = unknown> = {
  data?: InfiniteData<TData>
  error: TError | null
  status: QueryDataStatus
}

/**
 * An outline for a react query hook, which returns a status, data, and error
 * as well a method to manually refetch the data (if needed, which should be rare).
 * In general, passing different queries to the queryKey in a useQuery call
 * takes care of this for you.
 */
export type QueryHookReturn<
  TData,
  TError = DefaultError,
  TSelectData = TData,
> = BaseQueryStatus<TSelectData, TError> & {
  refetch?: UseQueryResult<TSelectData, TError>['refetch']
}

/**
 * An outline for an infinite react query hook, which returns a status, data, and error
 * as well a method to manually refetch the data (if needed, which should be rare).
 * In general, passing different queries to the queryKey in a useQuery call
 * takes care of this for you.
 */
export type InfiniteQueryHookReturn<
  TData,
  TError = DefaultError,
  TSelectData = TData,
> = BaseQueryStatus<TSelectData, TError> & {
  isFetching: boolean
  isFetchingNextPage: boolean
  hasNextPage: boolean
  fetchNextPage: (
    options?: FetchNextPageOptions,
  ) => Promise<InfiniteQueryObserverResult<TSelectData, TError>>
  refetch?: UseQueryResult<TSelectData, TError>['refetch']
}

export type QueryFnArgs<TTData> = {
  /**
   * The URL to fetch data from.
   */
  url: string
  /**
   * The schema to validate the data against.
   */
  schema?: ZOutputSchema<TTData>
  /**
   * The optional payload to send with the request.
   */
  payload?: Record<string, unknown>
}

/**
 * The shape of a custom query function.
 */
export type QueryFn<TData> = (props: QueryFnArgs<TData>) => Promise<TData>

export const enum StoreKeys {
  ES_CONFIG = 'es-config',
  ES_CONFIG_THEME = 'es-config-theme',
  ES_CONFIG_CONTENT = 'es-config-content',
  ES_CONFIG_AUDIO = 'es-config-audio',
}
