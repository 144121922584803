/**
 * Initialise our Cognito config.
 */

import { Auth } from '@aws-amplify/auth'
import { DateTime } from 'luxon'
import { z } from 'zod'

import type { ZOutputSchema } from '@apsys/sdk-console'

import type { UserAttributes, UserMetadata } from './types'

/** We're running in tests */
const test = import.meta.env.TEST

if (import.meta.env.VITE_COGNITO_POOL_ID) {
  console.info(
    `Configuring Cognito. Pool: ${import.meta.env.VITE_COGNITO_POOL_ID}`,
  )
} else if (!test) {
  console.error('No Cognito pool configured (COGNITO_POOL_ID)')
}

if (!import.meta.env.VITE_COGNITO_CLIENT_ID && !test) {
  console.error('No Cognito client id configured (COGNITO_CLIENT_ID')
}

if (!import.meta.env.VITE_COGNITO_REGION && !test) {
  console.error('No Cognito region configured (COGNITO_REGION)')
}

Auth.configure({
  region: import.meta.env.VITE_COGNITO_REGION,
  userPoolId: import.meta.env.VITE_COGNITO_POOL_ID,
  // N.B. the clientId must not be generated with a secret
  // secrets are not supported in the Amplify JS API
  userPoolWebClientId: import.meta.env.VITE_COGNITO_CLIENT_ID,
  mandatorySignIn: true,
})

/**
 * Schema for the user profile stored in Cognito.
 *
 * This is defined by the Cognito config and not static, so we assert the
 * shape of this object whenever we retrieve it from Cognito.
 *
 * This data comes from the identity token, but we retrieve it via a Cognito
 * API.
 */
export const AttributesSchema: z.ZodSchema<UserAttributes> = z.object({
  email: z.string(),
  email_verified: z.boolean().optional(),

  name: z.string().optional(),

  picture: z.string().optional(),
})

/**
 * Schema to parse the access token
 */
export const MetadataSchema: ZOutputSchema<UserMetadata> = z.object({
  sub: z.string(),
  auth_time: z
    .number()
    .int()
    .transform(value => DateTime.fromSeconds(value)),
  'cognito:groups': z.array(z.string()).default([]),
})
