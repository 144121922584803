import { useCallback } from 'react'

import { Auth } from '@aws-amplify/auth'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { defineQuerySideEffectRegistration } from '@apsys/sdk-console'

import { AttributesSchema, MetadataSchema } from '../views/login/cognito'

import { QueryDataStatus } from 'store/types'
import { combinedQueryStatus } from 'utils/queryStatus'
import { setSentryIdentifiers } from 'utils/sentry'
import { IdentifyQueryKeys } from 'views/login/components/constants'
import type {
  AuthenticationState,
  AuthUser,
  UserAttributes,
  UserMetadata,
} from 'views/login/types'
import { AuthenticationStatus } from 'views/login/types'

type UseAuthStateReturnType = AuthenticationState & {
  invalidate(): void
}

function getAuthStatus(
  status: QueryDataStatus,
  authUser?: AuthUser,
  userAttributes?: UserAttributes,
  sessionQueryData?: UserMetadata,
): AuthenticationStatus {
  if (status === QueryDataStatus.Loading || status === QueryDataStatus.Idle) {
    return AuthenticationStatus.Verifying
  }

  if (status === QueryDataStatus.Failed) {
    return AuthenticationStatus.LoggedOut
  }

  if (
    status === QueryDataStatus.Ready &&
    authUser &&
    userAttributes &&
    sessionQueryData
  ) {
    return AuthenticationStatus.LoggedIn
  }

  return AuthenticationStatus.Verifying
}

async function getCurrentlyAuthenticatedUser(): Promise<AuthUser | undefined> {
  return Auth.currentAuthenticatedUser()
}

async function getCurrentSession(): Promise<UserMetadata> {
  const session = await Auth.currentSession()
  return MetadataSchema.parse(session.getAccessToken().payload)
}

export function getUserAttributes(
  authUser?: AuthUser,
): UserAttributes | undefined {
  const parsed = authUser && AttributesSchema.safeParse(authUser.attributes)
  if (!parsed?.success) {
    return undefined
  }
  return parsed.data
}

/**
 * Determines user authentication status and
 * returns the user profile and metadata.
 */
export function useAuthState(): UseAuthStateReturnType {
  const queryClient = useQueryClient()

  const userQuery = useQuery({
    queryKey: [IdentifyQueryKeys.AuthUser],
    queryFn: getCurrentlyAuthenticatedUser,
    retry: false,
    refetchOnMount: false,
  })

  const sessionQuery = useQuery({
    queryKey: [IdentifyQueryKeys.CurrentSession],
    queryFn: getCurrentSession,
    retry: false,
    refetchOnMount: false,
  })

  const { data: authenticatedUser } = userQuery
  const { data: userMetadata } = sessionQuery

  const userAttributes = getUserAttributes(authenticatedUser)

  const queryStatus = combinedQueryStatus(
    [userQuery.status, sessionQuery.status],
    [userQuery.error, sessionQuery.error],
  )

  const status = getAuthStatus(
    queryStatus,
    userQuery.data,
    userAttributes,
    userMetadata,
  )

  const invalidate = useCallback((): void => {
    queryClient.invalidateQueries({ queryKey: [IdentifyQueryKeys.AuthUser] })
    queryClient.invalidateQueries({
      queryKey: [IdentifyQueryKeys.CurrentSession],
    })
  }, [])

  return {
    status,
    userMetadata,
    userAttributes,
    invalidate,
  }
}

export function getIsAuthenticated(authState: AuthenticationState): boolean {
  return authState.status === AuthenticationStatus.LoggedIn
}

export const AUTH_USER_QUERY_SIDE_EFFECT = defineQuerySideEffectRegistration(
  IdentifyQueryKeys.AuthUser,
  (authenticatedUser: AuthUser) => {
    const userAttributes = getUserAttributes(authenticatedUser)
    if (!authenticatedUser || !userAttributes) {
      return
    }

    setSentryIdentifiers({
      id: authenticatedUser.getUsername(),
      email: userAttributes.email,
      username: authenticatedUser.getUsername(),
    })
  },
)
