/**
 * This file was copied from apsys-eileen-web :)
 */

import type { BackendModule, TFunction } from 'i18next'
import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { z } from 'zod'
/**
 * Register an error map function into Zod.
 *
 * This function is designed to be used as a promise in the i18next
 * initialization flow.
 */
export const registerZodErrorMap = (t: TFunction): TFunction => {
  // These keys don't appear in the type, use Zod as a type guard
  const Schema = z.object({
    // Passed with some options in the discriminated union
    // We don't need to solve the discriminated union, so we just parse them out
    // with the custom params
    type: z.string().optional(),
    minimum: z.number().optional(),
    maximum: z.number().optional(),
    expected: z.string().optional(),
    received: z.string().optional(),
    // Passed for custom refinements
    params: z
      .object({
        i18nKey: z.string().optional(),
        code: z.string().optional(),
        interpolations: z.record(z.string(), z.coerce.string()).optional(),
      })
      .optional(),
  })

  // @ts-ignore
  z.setErrorMap(({ code, ...rest }) => {
    const {
      type,
      minimum,
      maximum,
      expected,
      received,
      params: { i18nKey, code: customCode, interpolations } = {},
    } = Schema.parse(rest)

    // We try 6 translation keys:
    // 1. (if defined) i18nKey is tried directly
    // 2. (if defined) zod.type.code.customCode: a more specific version of 4.
    // 3. (if defined) zod.type.code: a more specific version of 5.
    // 4. zod.code.customCode: a generic lookup for custom codes, i.e. required
    // 5. zod.code: a generic lookup for error codes
    // 6. zod._: a generic fallback message with the unhandled error code

    // Note: if `message:` was passed, this function will be ignored for
    // whatever was passed in the message.

    // Build these backwards
    // 4-6.

    const keys = [
      `zod_validation.${code}.${expected}.${received}`,
      `zod_validation.${code}.default`,
      `zod_validation.${code}.${customCode}`,
      `zod_validation.${code}`,
      'zod_validation._',
    ]

    // 2-3.
    if (type) {
      keys.unshift(
        `zod_validation.${type}.${code}.${customCode}`,
        `zod_validation.${type}.${code}`,
      )
    }

    // 1.
    if (i18nKey) {
      keys.unshift(i18nKey)
    }

    const message = t(keys, {
      code: customCode ? `${customCode}.${code}` : code,
      count: minimum ?? maximum,
      ...interpolations,
      ...rest,
    })

    return {
      message,
    }
  })

  return t
}

/**
 * A dynamic loader to load the translations in a code-split way using
 * `import()`.
 */
const dynamicLoader: BackendModule = {
  type: 'backend',
  init: () => {},
  create: () => {},
  read: async (language, namespace, callback) => {
    // Dynamically `import()` a locale file based on the `language` and
    // `namespace`
    try {
      const lang = language.toLowerCase()
      const resources = await import(`../locales/${lang}.json`)
      callback(null, resources)
    } catch (exc) {
      callback(exc as Error, false)
    }
  },
}

/**
 * i18n initialisation function, uses i18next.
 *
 * @category i18n
 */
export const i18n = (
  translations: any,
  supportedLngs: string[],
  fallbackLng: string[] | string,
): Promise<TFunction> =>
  i18next
    // passes i18n down to react-i18next
    .use(initReactI18next)
    // detect user language
    .use(LanguageDetector)
    // Dynamically import the translation file
    .use(dynamicLoader)
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      resources: translations,
      fallbackLng,
      supportedLngs,
      interpolation: {
        escapeValue: false, // react already safes from xss
        /**
         * Ensure that if we don't provide a variable for
         * interpolation, that an empty string is rendered;
         *
         * if `skipOnVariables` is true;
         *
         * t('some.key')
         * "some": {"key": "some value {{variable}}"}
         *
         * Will render "some value {{variable}}" in the UI,
         * which is not ideal for our scenario.
         *
         * If false,
         *
         * t('some.key')
         * "some": "key": "some value {{variable}}"
         *
         * will render "some value"
         */
        skipOnVariables: false,
      },
    })
    .then(registerZodErrorMap)
